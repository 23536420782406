<template>
  <div class="zoom">
    <iframe
        referrerpolicy="unsafe-url origin"
        style="all: initial; width:100%; height: 90vh;"
        allow="cross-origin-isolated autoplay speaker camera microphone encrypted-media display-capture fullscreen accelerometer ambient-light-sensor magnetometer geolocation midi"
        sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-modals allow-top-navigation allow-top-navigation-by-user-activation"
        encrypted-media
        :src="`/zoom/${id}/${meetingId}`"
        frameborder="0"></iframe>
    </div>
</template>

<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'ViewMeeting',
  mounted () {
    core.index()
    core.activeRoute()
    this.id = this.$route.params.id
    this.meetingId = this.$route.params.meetingId
  },
  beforeDestroy () {
  },
  data () {
    return {
      id: '',
      meetingId: ''
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
